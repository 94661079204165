import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavRoad from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Title,
  SubTitle,
  Button,
  Input,
  Span,
  Textarea,
  MessageParagraph,
} from "../../../StyleComponents/styles"

import * as base32 from "hi-base32"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  { name: "Base32 Encoder", link: "/base32-encoder/" },
]

const seeAlsoArray = [
  "/base32-decoder",
  "/base64-decoder",
  "/base64-encoder",
  "/binary-encoder",
]

function Base32Encoder(props) {
  let [input, setinput] = useState("")
  let [base64, setbase64] = useState("")
  let [errorElemnet, seterrorElemnet] = useState("")
  let fileName = useRef("")

  function inputC(e) {
    setinput(e.target.value)
    if (e.target.value !== "") {
      var binaryData = e.target.value
      var base64String = base32.encode(binaryData)
      setbase64(base64String)
    }
  }

  function downloadObjectAsJson(exportName) {
    let myfileName = fileName.current.value
    if (myfileName === "") {
      exportName = "unitpedia-base32"
    } else {
      exportName = myfileName
    }
    if (base64 === "") {
      seterrorElemnet("Please make the conversion before download")
    } else {
      var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(base64)
      var downloadAnchorNode = document.createElement("a")
      downloadAnchorNode.setAttribute("href", dataStr)
      downloadAnchorNode.setAttribute("download", exportName + ".txt")
      document.body.appendChild(downloadAnchorNode) // required for firefox
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    }
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Base32 Encoder, Encoder Base32 Online"
        description="Base32 Encoder, Encoder Base32 Online, Simple Encoder Base32 translator step by step Online and fast."
        keywords={[
          "Base32 Encode, Base32 converter, Base32 Encoder, Base32 Encoder translator,encode, Base32 Encoder online, Encoder Base32,encoder decoder Base32,Online Encoder Decoder,Fast Base32 Encoder.",
        ]}
      />
      <NavRoad listPages={navRoadArray} />
      <div className="container">
        <Title>Base32 Encoder</Title>
        <SubTitle>Encode Base32 online</SubTitle>

        <Span>Input to Encode Base32</Span>

        <Textarea value={input} onChange={inputC} />

        <MessageParagraph color="red">{errorElemnet}</MessageParagraph>
        <div>
          <Span>Output</Span>
          <Textarea value={base64} readOnly />
        </div>
        <br />
        <div>
          <Span>Filename </Span>
          <Input className="mb-3" width="150px" type="text" ref={fileName} />
          <Button
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            onClick={downloadObjectAsJson}
          >
            Download
          </Button>
        </div>
        <br />
        <h3>Base32</h3>
        <p>
          The Base32 allows to encode with 32 characters: <br />
          ABCDEFGHIJKLMNOPQRSTUVWXYZ234567 and the = symbol optionally used as
          the final character (as in Base64).
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default Base32Encoder
